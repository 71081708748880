import { toFormData } from "~/core/helpers";

export class UpdateCompilationDto {
  static create(...arg) {
    return new UpdateCompilationDto(...arg).model;
  }

  static from(compilationApi) {
    const compilation = { ...compilationApi };

    compilation.tickers = compilation.stocks.map(stock => stock.ticker);
    compilation.files = [];
    compilation.imageUrls = [...compilation.images];

    compilation.seoTitle = compilation.seo.title;
    compilation.seoDescription = compilation.seo.description;
    compilation.seoHeader = compilation.seo.header;
    compilation.isIndexable = compilation.seo.isIndexable;

    delete compilation.stocks;
    delete compilation.images;
    delete compilation.seo;

    return compilation;
  }

  constructor(compilation) {
    this.model = { ...compilation };

    delete this.model.tags;

    if (this.model.imageUrls) {
      this.model.imageUrls = this.model.imageUrls.filter(url => url);
    }

    if (this.model.files?.length) {
      this.model = toFormData(this.model);
    } else {
      delete this.model.files;
    }
  }
}
