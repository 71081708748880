<template>
  <div v-if="tags.length" class="d-flex flex-wrap">
    <v-chip v-for="tag in tags" :key="tag.name" class="mr-2 mb-2" color="primary">
      {{ tag.nameRu || tag.name }}
    </v-chip>
  </div>
  <div v-else>{{ status }}</div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  props: {
    tickers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,

      tags: [],
      fetchUid: ""
    };
  },
  computed: {
    status() {
      if (this.loading) return "Загрузка...";

      return "Отсутствуют";
    }
  },
  watch: {
    tickers: {
      immediate: true,
      handler(tickers) {
        this.fetchUid = tickers.join();

        if (tickers.length) {
          this.fetchTags(this.fetchUid);
        } else {
          this.tags = [];
        }
      }
    }
  },
  methods: {
    async fetchTags(fetchUid) {
      this.loading = true;

      try {
        const tags = await this.$axios.$get("references/tag", { params: { tickers: this.tickers } });

        if (this.fetchUid === fetchUid) {
          this.tags = tags;
        }
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Теги не получены: " + html);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
