<template>
  <div>
    <div v-for="(imageUrl, ind) of imageUrls" :key="ind">
      <v-text-field
        :value="imageUrl"
        :label="'URL картинки №' + (ind + 1)"
        prepend-icon="mdi-camera"
        clearable
        @input="input(ind, $event)"
      >
        <template v-slot:append-outer>
          <unsplash-picker @selected="selectedImg(ind, $event)" />

          <v-btn class="ml-2" icon @click="remove(ind)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>

    <v-btn v-if="imageUrls.length < maxCount" @click="add">Добавить картинку по URL</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    compilation: {
      type: Object,
      required: true
    }
  },
  computed: {
    maxCount() {
      return Math.max(0, 10 - this.compilation.files.length);
    },
    imageUrls() {
      return [...this.compilation.imageUrls];
    }
  },
  methods: {
    selectedImg(ind, photo) {
      this.input(ind, photo.urls.full);
    },

    input(ind, value) {
      const imageUrls = [...this.imageUrls];

      imageUrls[ind] = value;

      this.$emit("input", imageUrls);
    },
    add() {
      this.$emit("input", [...this.imageUrls, ""]);
    },
    remove(ind) {
      const imageUrls = [...this.imageUrls];

      imageUrls.splice(ind, 1);

      this.$emit("input", imageUrls);
    }
  }
};
</script>
