<template>
  <div>
    <v-switch v-model="isShowCarusel" :label="`Показать обложки: ${images.length} шт.`" color="primary" />

    <template v-if="isShowCarusel">
      <v-alert color="info" dark>Обложки загружаемые из файла будут отображены после сохранения</v-alert>

      <v-carousel v-if="images.length" height="450" :show-arrows="false">
        <v-carousel-item
          v-for="(url, ind) of images"
          :key="url"
          reverse-transition="fade-transition"
          transition="fade-transition"
          style="background-color: #eee"
        >
          <v-img :src="url" contain min-height="400" max-height="400">
            <template v-slot:placeholder>
              <v-row class="fill-height" align="center" justify="center">
                <div class="display-1">Обложка №{{ ind + 1 }}</div>
              </v-row>
            </template>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    imageUrls: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isShowCarusel: false
    };
  },
  computed: {
    images() {
      return this.imageUrls.filter(url => url);
    }
  }
};
</script>
