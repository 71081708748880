<template>
  <v-form autocomplete="off" @submit.prevent="$emit('submit')">
    <v-text-field :value="compilation.author" label="Автор" @input="setField('author', $event)" />

    <v-text-field :value="compilation.title" label="Название" @input="setField('title', $event)" />

    <v-textarea :value="compilation.subTitle" label="Анонс" auto-grow @input="setField('subTitle', $event)" />

    <div class="my-5">
      <v-text-field :value="compilation.url" label="URL" @input="setField('url', $event)" />
      <small><b>Ссылка на подборку: </b>{{ url }}</small>
    </div>

    <stock-table-picker :value="compilation.tickers" :max-count="20" @input="setField('tickers', $event)" />

    <div class="text-h6 my-6">Теги</div>

    <tag-list :tickers="compilation.tickers" />

    <div class="text-h6 my-6">Обложка</div>

    <images-preview class="mb-6" :image-urls="compilation.imageUrls" />

    <v-file-input
      :value="compilation.files"
      label="Обложка"
      placeholder="Выбрать файл"
      multiple
      show-size
      @change="setField('files', $event)"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>

    <images-picker :compilation="compilation" @input="setField('imageUrls', $event)" />

    <div class="text-h6 my-6">SEO</div>

    <v-text-field :value="compilation.seoTitle" label="Title страницы (SEO)" @input="setField('seoTitle', $event)" />

    <v-text-field
      :value="compilation.seoDescription"
      label="Description страницы (SEO)"
      @input="setField('seoDescription', $event)"
    />

    <v-text-field :value="compilation.seoHeader" label="H1 страницы (SEO)" @input="setField('seoHeader', $event)" />

    <v-checkbox v-model="isIndexableProxy" label="Индексация" />

    <v-checkbox v-model="isPublishedProxy" label="Опубликовано" />

    <div class="mt-4 text-right">
      <v-btn v-if="isCreating" class="mr-4" @click="$emit('clear')">Сброс</v-btn>
      <v-btn type="submit" color="accent" :loading="loading">Сохранить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { Compilation } from "~/libs/compilation/compilation";
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import TagList from "./tag-list";
import ImagesPicker from "./images-picker";
import ImagesPreview from "./images-preview";

export default {
  mixins: [editorFields],
  props: {
    compilation: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isCreating() {
      return !this.compilation.id;
    },

    url() {
      return Compilation.createFullUrl(this.compilation.url);
    },

    isIndexableProxy: {
      get() {
        return this.compilation.isIndexable;
      },
      set(value) {
        this.setField("isIndexable", value);
      }
    },
    isPublishedProxy: {
      get() {
        return this.compilation.isPublished;
      },
      set(value) {
        this.setField("isPublished", value);
      }
    }
  },
  components: {
    TagList,
    ImagesPicker,
    ImagesPreview
  }
};
</script>
